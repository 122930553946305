import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const KarunPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", justifyContent: "space-between" }}
        className="has-padding-4  has-bg-blue align-content-vcenter has-txt-white"
      >
        <div>
          <h1 className="has-txt-bold" style={{ marginBottom: "1rem" }}>
            Karuneshwari Global
          </h1>
          <h3>
            <span className="has-mb-5 tags has-txt-bold">Logo design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Print design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Web Design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Web Development</span>{" "}
          </h3>
        </div>
        <p>
          Giving an advertising agency a modern day agile approach towards
          problem solving, helping them in providing the best in class services
          to their clients. <br />
          <br />
          We helped the agency transform itself while maintaining a strong
          connection with its roots, the emblem was at the core of their
          business and we made sure it remained relevant in the modern day and
          age. Developing a design driven ecosystem we established brand
          touchpoints that brought the spotlight back right where it belonged
        </p>
      </div>

      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/karuneshwari/1.jpg"} alt="" width="100%" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/karuneshwari/2.jpg"} alt="" width="100%" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="600"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/karuneshwari/3.jpg"} alt="" width="100%" />
      </div>
    </div>

    <div className="grid-1-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/karuneshwari/4.jpg"} alt="" width="100%" />
      </div>
    </div>
  </Layout>
)

export default KarunPage
